/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  --max-width: 1170px;
  --fixed-width: 450px;
  --clr-orange-1: hsl(12, 83%, 98%);
  --clr-orange-2: hsl(14, 91%, 95%);
  --clr-orange-3: hsl(12, 89%, 89%);
  --clr-orange-4: hsl(13, 87%, 82%);
  --clr-orange-5: hsl(13, 88%, 68%);
  --clr-orange-6: hsl(13, 88%, 55%);
  --clr-orange-7: hsl(13, 74%, 49%);
  --clr-orange-8: hsl(13, 74%, 33%);
  --clr-orange-9: hsl(13, 73%, 25%);
  --clr-orange-10: hsl(13, 73%, 16%);
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  background-image: linear-gradient(
      rgba(149, 239, 255, 0.7),
      rgba(217, 239, 243, 0.7)
    ),
    url(../src/background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  /* box-shadow: inset rgba(0, 0, 0, 0.2); */
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: white;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  /* text-transform: capitalize; */

  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  margin-top: 3rem;
  text-align: center;
  font-size: 3rem;
}
h2 {
  margin-top: 3rem;
  text-align: center;
  font-size: 2rem;
}
h3 {
  margin-left: 10vw;
  margin-right: 10vw;
  max-width: 80vw;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.25rem;
}
h4 {
  margin-top: 3rem;
  text-align: center;
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* .opacityLayer {
  background-color: var(--clr-grey-5);
  opacity: 0.8;
  size: 100vw;
  background-size: cover;
} */

/* section */
.section,
.container {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.container {
  text-align: center;
  /* margin-bottom: 4rem; */
}
.btn {
  /* display: inline-block; */
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.3rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;

  /* text-transform: capitalize; */
  text-align: center;
  /* font-size: 1rem; */
  letter-spacing: var(--spacing);
  margin-top: 2rem;
  /* margin-left: 0.5rem;
  margin-right: 0.5rem; */
  cursor: pointer;
  transition: var(--transition);
  max-width: 10rem;
  max-height: 3rem;
  position: absolute;

  /* left: 50%; */
  right: 46%;
}
@media screen and (max-width: 992px) {
  .btn {
    left: 35%;
  }
}
.btn:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}
.item {
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  max-width: var(--fixed-width);
  margin: 2rem auto;
  align-items: center;
  border-radius: var(--radius);
}
.item button,
.item a {
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  letter-spacing: var(--spacing);
  cursor: pointer;
}
.item {
  padding: 1rem 2rem;
}
.item h4 {
  margin-bottom: 0;
}
.item p {
  margin-bottom: 0;
}
.modal {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-white);
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: var(--radius);
  text-transform: capitalize;
}

.modal p {
  margin-bottom: 0;
  color: var(--clr-red-dark);
}
.form {
  background: var(--clr-white);
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: var(--radius);
}
.form input {
  background: var(--clr-grey-10);
  border-color: transparent;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
}
.form-control {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
.form button {
  display: inline-block;
  background: var(--clr-black);
  color: var(--clr-white);
  border-color: transparent;
  margin-top: 1rem;
  letter-spacing: var(--spacing);
  padding: 0.15rem 0.25rem;
  text-transform: capitalize;
  border-radius: var(--radius);
  cursor: pointer;
}
.nav-links {
  /* max-width: var(90vw); */
  text-align: center;
  margin: 0 auto;
  margin-top: 1.5vw;
  /* margin-top: -0.8rem; */
  /* margin-left: 10rem; */
  margin-right: 5rem;
  /* margin-bottom: 4rem; */
}
.nav-links a {
  color: var(--clr-black);
}
.navbar {
  height: 5rem;
  max-width: 100vw;
  background-color: var(--clr-grey-9);
  padding-top: 0.5px;
  padding-bottom: 0.5px;
  opacity: 0.9;
  border-bottom: solid;
  border-color: white;
  display: flex;
}
.logo {
  /* padding: 2px; */
  /* margin-left: -120px; */
  margin-left: 1vw;
  margin-top: 1vw;
  /* background-color: white; */
}
@media screen and (max-width: 992px) {
  .nav-links {
    margin-top: 2rem;
    margin-left: 5px;
    margin-right: 5px;
  }
  .logo {
    /* margin-left: 0;
    margin-right: 0; */
    width: 15rem;
  }
  /* .btn {
    margin-left: 3px;
    margin-right: 3px;
  } */
}
.users {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem auto;
}
.users li {
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--clr-white);
  padding: 1rem 2rem;
  border-radius: var(--radius);
  text-align: left;
}

.users img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}
.users h4 {
  margin-bottom: 0.15rem;
}
.users a {
  color: var(--clr-grey-5);
  text-transform: capitalize;
}

.products {
  margin: 4rem 0;
  display: grid;
  gap: 2rem;
}
@media screen and (min-width: 576px) {
  .products {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
.product {
  background: var(--clr-white);
  border-radius: var(--radius);
}
.product img {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);

  width: 100%;
  height: 15rem;
  object-fit: cover;
}
.product h4 {
  margin-top: 1rem;
}

.product button {
  margin-bottom: 1rem;
  background: var(--clr-primary-5);
  border-radius: var(--radius);
  border-color: transparent;
  color: var(--clr-white);
  padding: 0.25rem 0.5rem;
  text-transform: capitalize;
  cursor: pointer;
}

.left {
  padding-top: 35px;
  padding-left: 15px;
  /* max-width: 50vw; */
  margin-left: 0;
}

.txt {
  background-color: var(--clr-grey-9);
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0;
}
.txt2 {
  padding-top: 20px;
  padding-bottom: 15px;
  margin-bottom: 0px;
  text-align: center;
}
.txt3 {
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;

  /* text-transform: capitalize; */

  /* font-size: 1rem; */
  letter-spacing: var(--spacing);

  transition: var(--transition);
  max-width: 10rem;
  max-height: 2.5rem;
}
.txt4 {
  background-color: var(--clr-grey-9);
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 1rem;
}
.solid {
  height: 10rem;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
}
.notSolid {
  height: 13rem;
  /* display: flex;
  flex-direction: column; */
  text-align: center;
  padding-top: 2rem;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  /* padding-top: 35px; */
}

.butn {
  background: var(--clr-primary-5);
  color: var(--clr-white);
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;

  text-transform: capitalize;
  text-align: center;
  font-size: 1rem;
  letter-spacing: var(--spacing);
  cursor: pointer;
  transition: var(--transition);
  max-width: 12rem;
  max-height: 2.5rem;
  display: inline-block;
}
.butn:hover {
  background: var(--clr-primary-1);
  color: var(--clr-primary-5);
}

.list {
  padding: 10px;
  /* padding-left: 30vw; */
  background-color: var(--clr-grey-9);
}
.footer {
  height: 2rem;

  margin-bottom: 0.5rem;
  padding-bottom: 1rem;
}
hr {
  border: 1px solid black;
}
/* input[type="text"],
select {
  width: 60%;
  height: 10rem;
  padding: 10px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 19vw;
}

input[type="submit"] {
  width: 100%;
  background-color: #4caf50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}
 */
/* .divv {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
} */

/* textarea {
  width: 60%;
  height: 10rem;
  padding: 10px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 19vw;
  resize: none;
} */

.msg {
  margin-top: 2rem;
  height: 9rem;
  width: 80%;
}
.msg2 {
  margin-top: 2rem;
  height: 2rem;
  width: 30%;
}
.notSolidSmoll {
  height: 1rem;
  /* display: flex;
  flex-direction: column; */
  text-align: center;

  margin-top: 1rem;
  margin-bottom: 2.5rem;
  /* padding-top: 35px; */
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 30px;

  background-color: var(--clr-grey-9);
  color: black;
  text-align: center;
}
